import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useMediaQuery } from '@react-hook/media-query'
import styled from '@emotion/styled'
import { HeadingContainer, Text } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import { BLACK_COLOR_RGB, WHITE_COLOR } from '../components/common/color'
import bp, { TABLET_PORTRAIT_DEVICE } from '../components/common/breakpoints'

import ReactSVG from '../images/icons/brands/react-logo.svg'
import JavascriptSVG from '../images/icons/brands/js-logo.svg'
import PythonSVG from '../images/icons/brands/python-logo.svg'
import AzureSVG from '../images/icons/brands/azure-logo.svg'
import AwsSVG from '../images/icons/brands/aws-logo.svg'
import SEO from '../components/seo'
import GatsbyImage from 'gatsby-image'
import ModalServiceWB from '../components/ServiceModal/ModalServiceWB'
import arrowIcon from '../images/teams/arrow_icon.png'
export const PRIMARY_COLOR = 'var(--primary-color)'

const ContainerAbout = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 120px 1fr 120px;
  row-gap: 4rem;
  width: 100%;
  min-height: 500px;
  padding: 3rem 3rem;
  background-image: ${(props) => `url(${props.image});`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 400px;
  overflow: hidden;
  box-sizing: border-box;
`

const TextLeft = styled(Text)`
  align-self: center;
  justify-self: start;
  max-width: 720px;
  color: ${BLACK_COLOR_RGB(0.5)};
`

const TextRight = styled(Text)`
  align-self: center;
  justify-self: flex-end;
  max-width: 720px;
  color: ${BLACK_COLOR_RGB(0.5)};
`

const TextCenter = styled(Text)`
  align-self: center;
  justify-self: center;
  max-width: 100%;
  color: ${BLACK_COLOR_RGB(0.5)};
  font-size: 1rem;
  font-weight: bold;
`

const Showcase = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const LogoContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: ${WHITE_COLOR};
  box-shadow: 10px 10px 20px ${BLACK_COLOR_RGB(0.1)};
`

const Iframe = styled.iframe`
  display: block;
  overflow: hidden;
  border: none;
  box-sizing: border-box;
`

const AboutUsTitle = styled.text`
  align-self: center;
  justify-self: center;
  max-width: 100%;
  color: ${BLACK_COLOR_RGB(0.5)};
  font-size: 1rem;
  font-weight: bold;
`

const AboutUsTitleContainer = styled.div`
  alignment: center;
`

const VideoContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;

  ${bp[1]} {
    max-width: 80%;
  }
`

const TextHighLight = styled.span`
  grid-area: text;
  color: ${PRIMARY_COLOR};
`

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'image'
    'headings'
    'text';
  grid-template-columns: 1fr;
  height: 50%;
  padding-bottom: 2rem;

  ${bp[1]} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'image image'
      'headings text';
  }

  ${bp[2]} {
    grid-template-columns: 3fr 2fr;
  }
`

const ImageContainer = styled.div`
  grid-area: image;
`

const HeadingsContainer = styled.div`
  grid-area: headings;
  justify-self: start;
  align-self: center;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;

  ${bp[1]} {
    width: initial;
    justify-self: center;
  }
`

const TextContainer = styled.div`
  grid-area: text;
  justify-self: center;
  align-self: center;
  padding: 1rem 2rem;
  box-sizing: border-box;
  line-height: 1.3;
`

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  text-transform: uppercase;
`

const SubHeading = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  color: ${PRIMARY_COLOR};
  margin-bottom: 0;

  ${bp[1]} {
    margin: initial;
  }
`

const TextHeading = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  color: ${BLACK_COLOR_RGB(0.4)};
  margin: 0 0 0.5rem;

  ${bp[1]} {
    margin: 1rem 0;
  }
`

const ArrowButton = styled.img`
  position: relative;
  height: 10vw;
  width: 10vw;
  top: -20vw;
  left: 60vw;
  cursor: pointer;
  ${bp[4]} {
    top: -18vw;
    left: 55vw;
  }
`


const logos = [ReactSVG, JavascriptSVG, PythonSVG, AzureSVG, AwsSVG]

const BrandsShowcase = () => {
  return (
    <Showcase>
      {logos.map((Logo, index) => (
        <LogoContainer key={`logo-${index + 1}`}>
          <Logo />
        </LogoContainer>
      ))}
    </Showcase>
  )
}

const AboutPage = ({ data }) => {
  const matchMobile = useMediaQuery(`(min-width: ${TABLET_PORTRAIT_DEVICE}px)`)
  const [show, setShow] = useState(false)
  const openModal = () => {
    setShow(true)
  }
  return (
    <AppLayout>
      <SEO title="About Us" canonical="about" />
      <HeadingContainer
        title="About us"
        subtitle=""
      />
      <TextCenter style={{ fontSize: `1.2vw` }}>

        <TextHighLight style={{ fontSize: `1.2vw` }}>We are young and Dynamic team of Software Developers, QA, DevOps, Data Engineers and Web3
          Developers looking to make a mark in the world of innovation.</TextHighLight> <br></br><br></br>

        ALIO IT is a boutique software development services company based in Mexico and the USA actively
        providing the following services: <br></br><br></br>

        <ImageContainer>
          <div>
            <GatsbyImage fluid={data['ServicesImg'].fluid} />
          </div>
        </ImageContainer>

        With wide experience developing software solutions for the following industries:<br></br><br></br>

        <div class="container text-center">
          <div class="row align-items-start">
            <div class="col" style={{ color: `#EF761F` }} >
              <li>EdTech</li>
              <li>Claims & Insurance</li>
              <li>Manufacturing</li>
              <li>Mining</li>
            </div>
            <div class="col" style={{ color: `#EF761F` }}>
              <li>FinTech</li>
              <li>Health Care</li>
              <li>Cybersecurity</li>
              <li>Smart Cities, etc</li>
            </div>
          </div>
        </div> <br></br><br></br>

        Our goal is that in 5 year from now we are one of the top Software Development firms that is sought
        out for work thanks to having a reputation of quality, integrity and delivery.<br></br><br></br>

      </TextCenter>
      <VideoContainer>
        <Iframe
          width="100%"
          height={!matchMobile ? '280' : '520'}
          src="https://www.youtube.com/embed/TBQazS1tyvM"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>

      <ContainerAbout image={data.mountainsImage.resize.src}>
        <TextLeft>
          We provide software development services for complex software
          requirements using cutting edge technology for both open source
          (Javascript, Python) and .NET (C# primarily) and cloud services such
          as AWS, GCP and/or Azure.
        </TextLeft>
        <BrandsShowcase />
        <TextRight>
          We also focus on continuous training and selecting only the best staff
          for the jobs required in fields of AI, ML (Predictive Analytics), etc.
          This makes us a key player in the region (Latin America) and hopefully
          soon in the world as well.
        </TextRight>
      </ContainerAbout>
      <ModalServiceWB show={show} setShow={setShow} />
      <ImageContainer>
        <div style={{ marginBottom: '-8vw' }}>
          <GatsbyImage fluid={data['WB1'].fluid} />
          <ArrowButton src={arrowIcon} onClick={() => openModal()} />
        </div>
      </ImageContainer>
      <ImageContainer>
        {['WB2', 'WB3'].map((imagename) => (
          <div style={{ marginBottom: '20px' }}>
            <GatsbyImage fluid={data[imagename].fluid} />
          </div>
        ))}
      </ImageContainer>
      <HeadingContainer
        title="Your remote team, but... Closer"
        subtitle="Our DNA"
      />
      <Container>
        <ImageContainer>
          {[
            'alioTeamComplete',
            'alfonso',
            'alioTeamGirls',
            'alioTeamMen',
            'alioTeamParkA',
            'alioTeamParkB',
            'alioTeamStairs',
          ].map((imagename) => (
            <div style={{ marginBottom: '10px' }}>
              <GatsbyImage fluid={data[imagename].fluid} />
            </div>
          ))}
        </ImageContainer>
      </Container>
    </AppLayout >
  )

}

export const query = graphql`
  query {
    mountainsImage: imageSharp(
      resize: { originalName: { eq: "mountains.png" } }
    ) {
      resize(jpegQuality: 100, quality: 100, width: 1200) {
        src
        originalName
      }
    }
    CEO: imageSharp(
      fluid: { originalName: { eq: "CEO-AlfonsoBonillas.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    ServicesImg: imageSharp(fluid: { originalName: { eq: "Services 4 orange.png" } }) {
      fluid(jpegQuality: 100, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    },
    arrowRight: imageSharp(fluid: { originalName: { eq: "arrow-right.png" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 10, maxHeight: 10) {
        ...GatsbyImageSharpFluid
      }
    }
    teamsBackground: imageSharp(
      fluid: { originalName: { eq: "teams-background.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alfonso: imageSharp(fluid: { originalName: { eq: "Alfonso.jpg" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamComplete: imageSharp(
      fluid: { originalName: { eq: "AlioTeamComplete.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamGirls: imageSharp(
      fluid: { originalName: { eq: "AlioTeamGirls.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamMen: imageSharp(
      fluid: { originalName: { eq: "AlioTeamMen.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamParkA: imageSharp(
      fluid: { originalName: { eq: "AlioTeamParkA.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamParkB: imageSharp(
      fluid: { originalName: { eq: "AlioTeamParkB.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamStairs: imageSharp(
      fluid: { originalName: { eq: "AlioTeamStairs.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 800) {
        ...GatsbyImageSharpFluid
      }
    }
    WB1: imageSharp(fluid: { originalName: { eq: "WB1.png" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
    WB2: imageSharp(fluid: { originalName: { eq: "WB2.png" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
    WB3: imageSharp(fluid: { originalName: { eq: "WB3.png" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default AboutPage
