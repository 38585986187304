import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import './modalService.css'

const ModalBody = (props) => {
  const { item, handleMoveLeft, handleMoveRight } = props

  return (
    <div className="body-container">
      <h1>Characteristics of Wellness Program for IT Professionals</h1>
      <ul>
        <li><strong>Flexibility:</strong> Recognize the irregular schedules and long hours common in IT jobs. Offer flexible
          scheduling for wellness activities and encourage breaks throughout the day.</li>
        <li><strong>Physical Health Initiatives:</strong> Provide access to ergonomic workstations, standing desks, and
          encourage regular physical activity through incentives such as gym memberships, fitness challenges, or yoga
          classes.</li>
        <li><strong>Mental Health Support:</strong> Offer resources for stress management, such as counseling services,
          mindfulness training, or meditation sessions. Promote work-life balance and encourage employees to take time off
          when needed.</li>
        <li><strong>Education and Training:</strong> Provide workshops or webinars on topics like time management,
          resilience building, or ergonomics. Offer resources for career development to reduce job-related stress.</li>
        <li><strong>Team Building Activities:</strong> Organize team outings or recreational activities to foster social
          connections and reduce feelings of isolation. Encourage team members to support each other's wellness goals.</li>
        <li><strong>Technology Detox:</strong> Encourage employees to take regular breaks from screens and technology. Offer
          incentives for unplugging during vacations or weekends.</li>
        <li><strong>Inclusive Approach:</strong> Recognize and accommodate diverse wellness needs within the IT workforce,
          considering factors like age, gender, cultural background, and physical abilities.</li>
        <li><strong>Feedback Mechanism:</strong> Establish channels for employees (one on ones) to provide feedback on the
          wellness program and suggest improvements. Regularly assess the program's effectiveness and make adjustments as
          needed.</li>
      </ul>
    </div>
  )
}

const ModalServiceWb = (props) => {
  const { show, setShow } = props
  const { logoBlack } = useStaticQuery(query)

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="special_modal"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <GatsbyImage fixed={logoBlack.fixed} />
          </Modal.Title>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="modal-button-close"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
      </Modal>
    </>
  )
}

const query = graphql`
  query {
    logoBlack: imageSharp(
      fixed: { originalName: { eq: "alio-logo-black.png" } }
    ) {
      fixed(height: 24) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default ModalServiceWb
